<template>
  <div class="main-cast-contain">
    <div class="cast-container">
      <div class="cast-image">
        <!-- <img class="picture-div" :src="castPicture" alt /> -->
      </div>
      <div class="cast-details">
        <h2 class="cast-name">{{ castName }}</h2>
        <p class="cast-dob">{{ castDob }}</p>
        <p class="cast-desc">{{ castDesc }}</p>
        <p class="cast-roles">
          Roles:
          <span>{{ castRoles }}</span>
        </p>
      </div>
    </div>
    <div class="work-container">
      <div class="work-box" v-for="(item, index) in castWorks" :key="index">
        <!-- <img :src="item.img" alt /> -->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      // castPicture: require("@/assets/Images/img2.jpg"),
      castName: "Asihwarya Menon",
      castDob: "31 Jan 1991",
      castDesc:
        "In the last five to six years the FTA satellite receiver has become an everyday household electronic device. People all over the world are buying free to air receivers because of their growing and always evolving features and capabilities. FTA receivers these days are gaining popularity all over. Starting initially in Europe.",
      castRoles: "Actor,Producer,Director",
      castWorks: []
    };
  },
  mounted() {
    this.castWorks = [
      // {
      //   img: require("@/assets/Images/img2.jpg"),
      // },
      // {
      //   img: require("@/assets/Images/img1.jpg"),
      // },
      // {
      //   img: require("@/assets/Images/img3.jpg"),
      // },
      // {
      //   img: require("@/assets/Images/img4.jpg"),
      // },
      // {
      //   img: require("@/assets/Images/img1.jpg"),
      // },
      // {
      //   img: require("@/assets/Images/img4.jpg"),
      // },
      // {
      //   img: require("@/assets/Images/img2.jpg"),
      // },
    ];
  }
};
</script>

<style lang="scss">
@import "@/sass/_variables.scss";
@import "./Casting.scss"
</style>
